import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
import ReactCardFlip from 'react-card-flip';

const Portfolio = () => {
  const isotope = useRef();
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    DETAILED: "AWS Health Solutions",
    ML: "ML Research Projects",
    MOCKUPS: "React Apps",
    // YOUTUBE: "Micro-videos",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "Empowering Patients: Self-Service Exam Scheduling",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Patient-centric chatbot application using Amazon Lex and AWS Lambda, streamlining the process of scheduling medical exams without the need for direct human intervention.",
        client: "Lambda to orchestrate the exam ordering process",
        technologies: "DynamoDB to store conversation and exam details",
        industry: "Textract to analyze exam order",
        date: "CloudFront to deliver chatbot application content",
        url: {
          name: "S3 to host the application and manage image data",
        },
        sliderImages: [
          "images/projects/chatbot-lambda.jpg",
          "images/projects/chatbot-schedule.jpg",
        ],
      },
      thumbImage: "images/projects/chatbot-exam-thumbnail.jpg",
      categories: [filters.DETAILED]
    },
    {
      title: "Automating GxP Compliance",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Using AWS CDK to define Infrastructure as Code (IaC)",
        client: "Service Catalog to empower developers with pre-approved, compliant resources.",
        technologies: "CloudTrail and Config for enabling auditing and compliance verification.",
        industry: "Fargate and ECS to orchestrate containerized applications, enabling a serverless architecture.",
        date: "CI/CD pipelines, integrated with ECR, to automate build and deployment processes.",
        url: {
          name: "DynamoDB to store state, configuration, and application data from ECS cluster.",
        },
        sliderImages: [
          "images/projects/gxp-config.jpg",
          "images/projects/gxp-code.jpg",
          "images/projects/gxp-dashboard.jpg",
        ],
      },
      thumbImage: "images/projects/gxp-compliance-thumbnail.jpg",
      categories: [filters.DETAILED]
    },
    {
      title: "Improving Surgical Care with IoT",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Collecting real-time surgical analytics to enhance surgical care.",
        client: "OpenSearch to provide scalable search and analytics capabilities.",
        technologies: "IoT Core to enable secure real-time communication between IoT devices and AWS.",
        industry: "Lambda to transform raw biosignal data for real-time analytics.",
        date: "Kinesis to facilitate real-time data high-throughput streaming.",
        url: {
          name: "DynamoDB to provide storage and rapid access to enriched biosignal data.",
        },
        sliderImages: [
          "images/projects/IoT-opensearch.jpg",
          "images/projects/IoT-opensearch-fields.jpg",
          "images/projects/IoT-opensearch-dashboard.jpg",
        ],
      },
      thumbImage: "images/projects/surgical-iot-thumbnail.jpg",
      categories: [filters.DETAILED]
    },
    {
      title: "Predicting Readmission Risk",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Improving patient outcomes and avoiding financial penalties by predicting readmission risk.",
        client: "Web App: Enables hospital staff to assess readmission risks.",
        technologies: "API Gateway: Serves as communication entry point, forwarding requests to the backend.",
        industry: "Lambda: Executes business logic to process the requests.",
        date: "SageMaker: Utilizes SageMaker Studio, Endpoint, and Model for predictive analytics.",
        url: {
          name: "Glue: Handles ETL.",
        },
        sliderImages: [
          "images/projects/readmission-database.jpg",
          "images/projects/readmission-tables.jpg",
          "images/projects/readmission-hyperparameters.jpg",
        ],
      },
      thumbImage: "images/projects/readmission-thumbnail.jpg",
      categories: [filters.DETAILED]

    },
    {
      title: "Connected Ambulances",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Real-time emergency response solution using AWS IoT Core to enable connected ambulances to transmit vitals and location to hospital ER departments.",
        client: "Security by managing X.509 certifications IoT policies.",
        technologies: "Lambda functions to process incoming ambulance data streams.",
        industry: "Kinesis for real-time high-volume telemetry data from ambulances.",
        date: "OpenSearch for real-time dashboards.",
        url: {
          name: "Amazon Location Service to track ambulance positioning.",
        },
        sliderImages: [
          "images/projects/ambulance-route.jpg",
          "images/projects/ambulance-IoT.jpg",
          "images/projects/ambulance-lambda.jpg",
          "images/projects/ambulance-triggers.jpg",
          "images/projects/ambulance-opensearch.jpg",
        ],
      },
      thumbImage: "images/projects/ambulance-thumbnail.jpg",
      categories: [filters.DETAILED]

    },
    {
      title: "Patient Visit Transcription",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Enabling secure capture, transcription, and analysis of patient visit data using Transcribe Medical and HealthLake.",
        client: "Lambda: Processes voice data, triggers transcription, and integrates with HealthLake.",
        technologies: "Transcribe Medical: Converts voice recordings into text.",
        industry: "API Gateway: Handles website API requests.",
        date: "HealthLake: Stores and analyzes medical data.",
        url: {
          name: "Comprehend Medical: Extracts medical entities from text.",
        },
        sliderImages: [
          "images/projects/transcription-healthlake.jpg",
          "images/projects/transcription-transcribe.jpg",
          "images/projects/transcription-full.jpg",
          "images/projects/transcribe-cloudfront.jpg",
        ],
      },
      thumbImage: "images/projects/transcription-thumbnail.jpg",
      categories: [filters.DETAILED]

    },
    {
      title: "AWS Solutions Showcase providing short videos filterable by topic. ",
      type: types.IMAGE,
      thumbImage: "images/projects/react-biocloud23-portfolio.jpg",
      backImage: "images/projects/mockup-back.jpg",
      categories: [filters.MOCKUPS]

    },
    {
      title: "React App showcasing AWS health-related solutions and GitHub Repo Overviews",
      type: types.IMAGE,
      thumbImage: "images/projects/react-thumbnail.jpg",
      backImage: "images/projects/mockup-back-2.jpg",
      categories: [filters.MOCKUPS]
    },

    {
      title: "Mockups Design 3",
      type: types.IMAGE,
      thumbImage: "images/projects/react-BioPortfolio.jpg",
      backImage: "images/projects/mockup-back-3.jpg",
      categories: [filters.MOCKUPS]
    },

    {
      title: "AWS Amplify to streamline the deployment and management of React app, with CloudFront for content delivery and Route 53 for DNS",
      type: types.IMAGE,
      thumbImage: "images/projects/folio23-aws.jpg",
      backImage: "images/projects/mockup-back.jpg",
      categories: [filters.MOCKUPS]
    },

    {
      title: "Allows users to customize their experience by selecting personalized themes, including various background and font colors.",
      type: types.IMAGE,
      thumbImage: "images/projects/folio23-theme.jpg",
      backImage: "images/projects/mockup-back.jpg",
      categories: [filters.MOCKUPS]
    },

    {
      title: "Responsive design for mobile, tablet, and desktop views",
      type: types.IMAGE,
      thumbImage: "images/projects/folio24-ipad-pro.jpg",
      backImage: "images/projects/mockup-back.jpg",
      categories: [filters.MOCKUPS]
    },

    {
      title: "AWS Solutions Showcase - Explore project videos on healthcare and genomics, filterable by topic.  Each project features a short overview video.",
      type: types.IMAGE,
      thumbImage: "images/projects/folio24-beyond-resume.jpg",
      backImage: "images/projects/mockup-back.jpg",
      categories: [filters.MOCKUPS]
    },

    {
      title: "AWS Solutions Showcase - Explore project videos on healthcare and genomics, filterable by topic.  Each project features a short overview video.",
      type: types.IMAGE,
      thumbImage: "images/projects/folio24-aws.jpg",
      backImage: "images/projects/mockup-back.jpg",
      categories: [filters.MOCKUPS]
    },

    {
      title: "User sign-in using AWS Cognito. Cognito reduced unauthorized access and provides authentication",
      type: types.IMAGE,
      thumbImage: "images/projects/folio23-login.jpg",
      backImage: "images/projects/mockup-back.jpg",
      categories: [filters.MOCKUPS]
    },


  

    {
      title: "UCI Machine Learning Repository",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Glioma grading prediction (LGG OR GBM)",
        client: "Explores logistic regression, random forest and SVM models",
        technologies: "Generates pdf report of findings and insights",
        industry: "Leverages PyTorch's computational capabilities and neural network model integration",
        date: "Uses early stopping and regularization techniques to prevent overfitting",
        url: {
          name: "https://github.com/OpenBioResearch/disease-focused-uci-ml-repos.git",
          link: "https://github.com/OpenBioResearch/disease-focused-uci-ml-repos.git",
        },
        sliderImages: [
          "images/projects/uci-glioblastoma-loss.jpg",
          "images/projects/uci-glioblastoma-metrics.jpg",
        ],
      },
      thumbImage: "images/projects/uci-glioma-thumbnail.jpg",
      categories: [filters.ML]
    },

    {
      title: "Display of thumbnails for svs whole slide images (WSI) ",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Display of thumbnails for svs whole slide images (WSI)",
        client: "Uses openslide, a library specifically designed to handle digital pathology images.",
        technologies: "Thumbnail generation for quick previews",
        industry: "Designed for integration into larger medical imaging pipelines",
        date: "Slide metadata available",
        url: {
          name: "https://github.com/OpenBioResearch/py-wsi-digital-pathology.git",
          link: "https://github.com/OpenBioResearch/py-wsi-digital-pathology.git",
        },
        sliderImages: [
          "images/projects/svs-kenodo.jpg",
          "images/projects/svs-openslide.jpg",
        ],
      },
      thumbImage: "images/projects/svs-thumbnails.jpg",
      categories: [filters.ML]
    },

    {
      title: "Visualizing DICOM Data with pydicom",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Intensity statistics and interactive visualizations",
        client: "Leverages ipywidgets",
        technologies: "Enables quick visualization without downloading",
        industry: "Employes the pydicom library to read DICOM images and extract pixel array data for analysis",
        date: "Uses Boto3 library to interface with S3 bucket housing DICOM images",
        url: {
          name: "https://github.com/OpenBioResearch/medical-imaging-metrics.git",
          link: "https://github.com/OpenBioResearch/medical-imaging-metrics.git",
        },
        sliderImages: [
          "images/projects/dicom-contrast.jpg",
          "images/projects/dicom-histogram.jpg",
          "images/projects/dicom-overlay.jpg",
        ],
      },
      thumbImage: "images/projects/dicom-view.jpg",
      categories: [filters.ML]
    },

    {
      title: "Imaging Data Commons (IDC)",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Flask-based project integrating with Imaging Data Commons (IDC) API",
        client: "Provides for DICOM metadata extraction from an AWS S3 bucket.",
        technologies: "Enables dynamic interaction with IDC collections",
        industry: "Facilitates IDC exploration for further research",
        date: "Availability of de-identified DICOM images",
        url: {
          name: "https://github.com/OpenBioResearch/idc-imaging.git",
          link: "https://github.com/OpenBioResearch/idc-imaging.git",
        },
        sliderImages: [
          "images/projects/idc-aws.jpg",
          "images/projects/idc-filtered-image-type.jpg",
        ],
      },
      thumbImage: "images/projects/idc-imaging-thumbnail.jpg",
      categories: [filters.ML]
    },

    {
      title: "Sklearn Diabetes dataset in conjunction with ML regression algorithms",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Diabetes dataset used in conjunction with ML regression algorithms",
        client: "Lasso Regularization Helps Feature Selection",
        technologies: "Ridge Regression Stability Demonstrated",
        industry: "Ridge Regression Shows U-Shaped Error Trend",
        date: "Features Influence Lasso Accuracy",
        url: {
          name: "https://github.com/OpenBioResearch/scikit_diabetes.git",
          link: "https://github.com/OpenBioResearch/scikit_diabetes.git",
        },
        sliderImages: [
          "images/projects/diabetes-lasso-regression.jpg",
          "images/projects/diabetes-model-comparison.jpg",
          "images/projects/diabetes-cross-validation.jpg",
        ],
      },
      thumbImage: "images/projects/diabetes-thumbnail.jpg",
      categories: [filters.ML]
    },

    {
      title: "Natural Language Processing (NLP)",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Extracting key clinical insights from clinical notes",
        client: "NLP pipeline for analyzing clinical notes, leveraging ClinicalBERT, Stanza biomedical models, and spaCy",
        technologies: "Named Entity Recognition (NER): Identifies entities in text data",
        industry: "Topic Modeling: Discovers themes or topics in text",
        date: "Relation Extraction: Identifies relationships between entities",
        url: {
          name: "https://github.com/OpenBioResearch/nlp-clinical-notes.git",
          link: "https://github.com/OpenBioResearch/nlp-clinical-notes.git",
        },
        sliderImages: [
          "images/projects/nlp-cluster.jpg",
          "images/projects/nlp-word-cloud.jpg",
        ],
      },
      thumbImage: "images/projects/nlp-thumbnail.jpg",
      categories: [filters.ML]
    },
/*     {
      title: "YouTube Video",
      type: types.VIDEO,
      video: {
        vimeo: false,
        id: "PMNnEEEacCg",
      },
      thumbImage: "images/projects/project-3.jpg",
      categories: [filters.YOUTUBE],
      backText: "Watch this engaging YouTube video showcasing our project."
    }, */
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    return () => {
      isotope.current.destroy();
    };
  }, [isRtl]);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded > 0) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
    return "";
  };


  return (
    <>
      <section id="portfolio" className={"section portfolio-section"}>
        <div className={"container"}>
          {/* Heading */}
          <div className="row mb-5 wow fadeInUp">
            <div className="col-lg-9 col-xl-8 mx-auto text-center">
              <h2 className="fw-600 mb-3">Portfolio</h2>
              <hr className="heading-separator-line border-primary border-2 opacity-10 mx-auto" />
              <p className="text-4 text-muted">
              This portfolio section features images from AWS solutions, ML projects on GitHub, and React apps that I have developed.
              </p>
            </div>
          </div>

          {/* Filter Menu */}
          <ul className="portfolio-menu nav nav-pills text-uppercase justify-content-center border-bottom-0 mb-5 wow fadeInUp">
            <li className="nav-item">
              <button
                className={
                  "nav-link rounded-pill " + (filterKey === "*" ? "active" : "")
                }
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link rounded-pill " +
                    (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                     <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setImagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 "></button>
                          <div className="portfolio-overlay-details">
                            <p className="text-white text-6">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white fw-400">
                              {project?.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        {/* Modal */}
        {isOpen && (
          <ProjectDetailsModal
            projectDetails={selectedProjectDetails}
            setIsOpen={setIsOpen}
          ></ProjectDetailsModal>
        )}
      </>
    );
  };
  
  export default Portfolio;
