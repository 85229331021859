export const IntroSecDetails = {
  // heading1: "WELCOME TO MY WORLD",
  typeWritterHeadings: [
      "I'm Ileen Jhaney",
      "Clinical Genomics Solutions Architect",
      "Healthcare and Life Sciences Cloud Architect",
  ],
  heading2: "based in Atlanta, Georgia.",
  btnText: "Download CV",
};

export const AboutSecDetails = {
  image: {
      img: "images/about-me.jpg",
  },
  information: {
      title1: "About Me",
      name: "Ileen Jhaney",
      desc1:
          "My career journey of over 25+ years has taken me from implementing HL7 interfaces at Nellcor Puritan Bennet, to supporting patient care systems at MD Anderson Cancer Center, through overseeing informatics and LIMS platforms at the CDC, and finally to developing and deploying cloud-based microbiome and clinical genomics solutions at Emory University.",
      desc2:
          "My degrees in Public Health (Master of Science in Public Health, MSPH) and Health Information Management (Bachelor's degree) alongside my AWS certifications (Machine Learning Specialty (MLS-C01) and Solutions Architect Associate (SAA-C03) have enabled me to deliver resilient solution architectures and generate actionable analytic insights for biomedical research and clinical trials.",
  },
  moreAbout: [
      // Additional about details if needed
  ],
};

export const ServicesDetails = {
  heading: {
      title: "Industries and Expertise",
      text: "Empowering health and life science organizations with cloud infrastructure and machine learning solutions.",
  },
  details: [
      {
          name: "Solutions Architecture",
          desc: "Design, develop and deploy secure, performant and scalable cloud-based public health and life sciences solutions.",
          icon: "fab fa-aws",
      },
      {
          name: "Genomics Solutions",
          desc: "Build and deploy genomic pipelines and tools to support precision medicine, clinico-genomic research and population health.",
          icon: "fas fa-dna",
      },
      {
          name: "Healthcare Platforms",
          desc: "Implement cloud-based clinically driven platforms for hospital systems interoperability and regulatory compliance.",
          icon: "fas fa-stethoscope",
      },
      {
          name: "Machine Learning",
          desc: "Incorporate AWS Machine Learning Specialty certification and Research Informatics background to architect end-to-end machine learning environments.  ",
          icon: "fas fa-brain",
      },
      {
          name: "Analytics",
          desc: "Support research initiatives by developing and maintaining omics pipelines, analyzing multi-modal datasets, and providing evidence-based clinical insights.",
          icon: "fas fa-chart-area",
      },
      {
          name: "Proof-of-Concepts",
          desc: "Develop proof-of-concept architectures to assess and ensure technical feasibility, regulatory compliance, and risk management prior to full-scale deployment.",
          icon: "fas fa-wrench",
      },
  ],
};

// Removed the MyWorking_Skills export entirely as it was related to "Why I'm Different"

export const CallToActionDetails = {
  heading: "Start a project with Me today!",
  subHeading: "I am Available for Freelancer.",
  btnText: "Hire Me",
};

export const WorkProcessDetails = {
  heading: {
      title: "Beyond the Resume",
      text: "Beyond the bullet points, this Question & Answer section offers insights into my skill sets, inspirations and professional qualities ",
  },
  WDetails: [
      {
          sno: 1,
          name: "Which cloud computing roles do you envision for yourself?",
          desc: "I am passionate about pursuing AWS Cloud Engineer and Solutions Architect roles, leveraging my skills to design and develop cloud architectures for healthcare, life sciences, and clinicogenomics solutions, particularly those involving multi-modal projects with digital pathology whole slide images, genomics, clinical datasets, and DICOM images.",
      },
      {
          sno: 2,
          name: "How would a former colleague or user describe you in three words? Why?",
          desc: "Professional, conscientious, and focused. I prioritize integrity and reliability, known for meticulous attention to detail and commitment to quality. I'm organized, detail-oriented, and dedicated to high standards. When facing technical challenges, I leverage analytical skills to systematically identify and resolve root causes.",
      },
      {
          sno: 3,
          name: "What inspires you to go above and beyond in your work?",
          desc: "My drive comes from crafting solutions that are secure, functional, and maintainable. I design with all levels of users, developers, and support personnel in mind. Staying current with new technologies motivates me to continually evolve. I also take pride in developing innovative cloud-based solutions that can serve as templates for future projects, ensuring lasting impact and efficiency."
      },
      {
          sno: 4,
          name: "What are your core technical skills and how have you applied them in past projects? ",
          desc: "My core technical skills lie in prototyping and building AWS solutions tailored to healthcare, clinicogenomics and biomedical informatics projects. My proficiency in Python enables me to build  Lambda functions and to develop machine learning solutions. I'm passionate about digital pathology and dedicate my personal time to constructing Python scripts for analyzing digital pathology whole slide images. ",
      },
      {
          sno: 5,
          name: "What is something unique about your approach to your field work? ",
          desc: "My career began with coding medical records, which later evolved into coding applications. This journey gave me a deep understanding and appreciation of patient confidentiality. I excel at breaking down projects into essential components, strategically aligning them with overall objectives, and staying deeply involved and technically capable in developing solutions.",
      },
      {
          sno: 6,
          name: "What are you most proud of in your career so far? ",
          desc: "I am deeply proud of the technical acumen I've gained over my career, as well as my strong commitment to teamwork. These strengths have enabled me to effectively contribute to and lead projects, ensuring that both the technical and collaborative aspects are executed to the highest standard!",
      },
  ],
};

export const OurClientsDetails = {
  heading: {
      title: "Our Clients",
      text: "See what my happy clients say about me. I'm proud to have my clients.",
  },
  clients: [
      {
          name: "Jay Shah",
          img: "images/testimonial/client-sm-1.jpg",
          exp: "Founder at Icomatic Pvt Ltd",
          summary:
              " Easy to use, reasonably priced simply dummy text of the printing and typesetting industry. Quidam lisque persius interesset his et, in quot quidam possim iriure. simply dummy text of the printing and typesetting industry.",
      },
      {
          name: "Patric Cary",
          img: "images/testimonial/client-sm-2.jpg",
          exp: "Freelancer from USA",
          summary:
              " “I am happy Working with printing and typesetting industry.Quidam lisque persius interesset his et, in quot quidampersequeris essent possim iriure. simply dummy text of the printing and typesetting industry.”",
      },
      {
          name: "Dennis Jacques",
          img: "images/testimonial/client-sm-3.jpg",
          exp: "iDeal Inc",
          summary:
              " “Only trying it out since a few days. But up to now excellent.Seems to work flawlessly. priced simply dummy text of the printing and typesetting industry.”",
      },
      {
          name: "Chris Tom",
          img: "images/testimonial/client-sm-4.jpg",
          exp: "Company CEO from UK",
          summary:
              " “I have used them twice now. Good rates, very efficient service and priced simply dummy text of the printing and typesetting industry quidam interesset his et. simply dummy text of the printing and typesetting industry. Excellent.”",
      },
  ],
  posters: [
      {
          id: 1,
          img: "images/clients/client-logo2.png",
      },
      {
          id: 2,
          img: "images/clients/client-logo3.png",
      },
      {
          id: 3,
          img: "images/clients/client-logo1.png",
      },
      {
          id: 4,
          img: "images/clients/client-logo2.png",
      },
      {
          id: 5,
          img: "images/clients/client-logo3.png",
      },
      {
          id: 6,
          img: "images/clients/client-logo1.png",
      },
      {
          id: 7,
          img: "images/clients/client-logo3.png",
      },
      {
          id: 8,
          img: "images/clients/client-logo1.png",
      },
      {
          id: 9,
          img: "images/clients/client-logo2.png",
      },
      {
          id: 10,
          img: "images/clients/client-logo3.png",
      },
      {
          id: 11,
          img: "images/clients/client-logo1.png",
      },
      {
          id: 12,
          img: "images/clients/client-logo2.png",
      },
  ],
};

export const ContactDetails = [
  {
      icon: "fas fa-map-marker-alt",
      title: "Visit Us",
      p1: "145 Murphy Canyon Rd.",
      p2: " Suite 100-18, San Diego CA 2028",
  },
  {
      icon: "fas fa-phone-alt",
      title: "Call Us Now",
      p1: "Phone: (+060) 9898980098",
      p2: " Fax: (+060) 8898880088",
  },
  {
      icon: "fas fa-envelope",
      title: "Inquiries",
      p1: "info@kenilpatel.com",
      p2: " Mon to Fri (10 am – 8 pm)",
  },
];

export const socialIcons = [
  {
      title: "Linkedin",
      icon: "fab fa-linkedin-in",
      href: "http://www.linkedin.com/",
      className: "social-icons-linkedin",
  },
  {
      title: "GitHub",
      icon: "fab fa-github",
      href: "http://www.github.com/",
      className: "social-icons-github",
  },
];
