import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="section bg-dark" data-bs-theme="dark">
      <div className="container wow fadeInUp">
        <div>
          <p className="text-muted text-center">
            Copyright © 2024.  All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
