import React from "react";

const AboutMoreModal = () => {
  return (
    <div
      id="about-us-details"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">About Me... More</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">

            <div className="featured-box style-1">
              <div className="featured-box-icon text-primary">
                <img src="/emory.png" alt="Emory" style={{ width: '45px', height: '45px' }} />
                <img src="/CDC.png" alt="CDC" style={{ width: '50px', height: '50px' }} />
              </div>
              <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Experience </h4>
              <p>
                Over the latest 14 years of my career, spanning Emory University and the CDC, I’ve led solutions architecture projects for clinical genomics and biomedical research, developed web applications for microbial taxonomy, and provided critical support for enterprise LIMS systems.
              </p>
            </div>

            <div className="featured-box style-1">
              <div className="featured-box-icon text-primary">
                <img src="/saa-c03.png" alt="AWS Solutions Architect - Associate (SAA-C03)" style={{ width: '50px', height: '50px' }} />
                <img src="/MLS-C01.png" alt="AWS Machine Learning – Specialty (MLS-C01)" style={{ width: '50px', height: '50px' }} />
              </div>
              <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AWS Certifications</h4>
              <p style={{ marginBottom: '8px' }}> {/* Adjusted margin-bottom to reduce space */}
                <a href="https://www.credly.com/badges/65db88c8-94f7-44a0-b4a3-d5664371bb4f/public_url" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', color: '#1e40af', textDecoration: 'none' }}>
                  AWS Solutions Architect - Associate (SAA-C03)
                </a><br />
                <span style={{ color: '#4b5563' }}>Expires May 2026</span><br />
              </p>
              <p style={{ marginTop: '0', marginBottom: '8px' }}> {/* Removed top margin and adjusted bottom margin */}
                <a href="https://www.credly.com/badges/2e3aaabe-134a-4539-912c-fb410a8adb42/public_url" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', color: '#1e40af', textDecoration: 'none' }}>
                  AWS Machine Learning – Specialty (MLS-C01)
                </a><br />
                <span style={{ color: '#4b5563' }}>Expires March 2027</span>
              </p>
            </div>

            <div className="featured-box style-1">
              <div className="featured-box-icon text-primary">
              <img src="/github.png" alt="Emory" style={{ width: '45px', height: '45px' }} />
              </div>
              <h4>GitHub</h4>
              <p>
              My GitHub site is <a href="https://github.com/OpenBioResearch">https://github.com/OpenBioResearch</a>.  I explore machine learning models using open-access clinical datasets and digital pathology images to gain clinical insights.


              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMoreModal;
